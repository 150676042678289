import React from 'react';
import "./credits.css"

const Credits = () => {
    return (
        <div id='credits'>
            <h2 className='title title-credits'>Site Credits</h2>
            <div className='container-text-credits'><span className='bold-credits'>Website & Design :</span><a className='credits-website' href='https://valentinavittoria.com/'rel="noreferrer" target='_blank'> Valentina Vittoria</a></div>
            <div className='container-text-credits'>
                <div className='bold-credits pictures'>Pictures :</div>
                <ul>
                    <li>+ Green Plant on White Wooden Table by Daria Shevtsova</li>
                    <li>+ Modern studio apartment interior by Max Rahubovskiy</li>
                    <li>+ Interior of Clothing Store by Rachel Claire </li>
                </ul>
            </div>
            <div className='back-home-text-container'>
                <a href='/' className=''>Torna Indietro</a>
            </div>
        </div>
    );
}

export default Credits;
