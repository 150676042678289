import React from 'react';
import "./footer.css"

const Footer = () => {
    return (
        <div className='footer'>
            <p> A.G Pulizie - Adriana Vittoria <br />
                E-mail: adrianavittoriaav@gmail.com - Cell: +39 366 973 1155 - +39 3516680286 - Sede legale: Via S. Domenico 42 – 80127 Napoli NA Italy – <br />
                P.IVA IT09461791213 | Privacy policy <br />
            </p>
            <div className='container-copy-credits'>
            <span className='copyright'>&copy;Copyright 2023 AG.Pulizie |</span>
            <span className='credits'><a href='/credits'>Site Credits</a></span>
            </div>

        </div>
    );
}

export default Footer;
