import "./privacy-policy.css";
const PrivacyPolicy = () => {
    return (
        <div className="page-container">
            <div id="privacy-policy">
                <h1>Informativa sulla Privacy e sui Cookie</h1>
                <div className="privacy-policy-container">
                    A.G. Pulizie (di seguito “l’Azienda”), nel rispetto delle normative europee sulla protezione dei dati personali, in particolare il Regolamento (UE) 2016/679 (GDPR), desidera informarti su come vengono trattati i tuoi dati personali quando visiti il nostro sito web e quando invii una candidatura per una posizione lavorativa.
                </div>
                <div id="section-cookies" className="privacy-policy-container">
                    <h2>1. Uso dei Cookie di Google Analytics</h2>
                    <div className="privacy-policy-subcontainer">
                        Il nostro sito utilizza Google Analytics, un servizio di analisi web fornito da Google, Inc. ("Google"), per raccogliere informazioni anonime sull’uso del sito e migliorare la tua esperienza di navigazione.
                    </div>
                    <h3>Quali dati raccogliamo tramite Google Analytics?</h3>
                    <ul className="privacy-policy-list-container">
                        <li>Indirizzo IP (anonimizzato)</li>
                        <li>Informazioni di navigazione: pagine visitate, tempo di permanenza sul sito, browser utilizzato, sistema operativo.</li>
                    </ul>
                    <div className="privacy-policy-subcontainer">Queste informazioni sono raccolte in forma anonima e aggregata, pertanto non consentono l’identificazione diretta dell’utente. La raccolta di questi dati è finalizzata esclusivamente a migliorare il funzionamento del sito.</div>
                    <h3>Come gestire i Cookie?</h3>
                    <div className="privacy-policy-subcontainer">Puoi gestire le preferenze sui cookie attraverso il tuo browser, disabilitando i cookie di Google Analytics. Tieni presente che disabilitando i cookie potresti limitare alcune funzionalità del sito.

                        Per ulteriori informazioni, ti invitiamo a consultare la <a href='https://policies.google.com/technologies/partner-sites' target="_blank" rel="noreferrer">Privacy Policy di Google Analytics.</a></div>
                </div>
                <div id="section-trattamento-dati-candidature" className="privacy-policy-container">
                    <h2>2. Trattamento dei dati per candidature</h2>
                    <div className="privacy-policy-subcontainer">Attraverso il nostro sito puoi inviare la tua candidatura per una posizione lavorativa. A tal fine, raccogliamo e trattiamo i seguenti dati personali forniti tramite il form di candidatura:</div>
                    <ul className="privacy-policy-list-container">
                        <li>Nome e cognome</li>
                        <li>Indirizzo e-mail</li>
                        <li>Numero di telefono</li>
                        <li>Esperienza lavorativa (Curriculum Vitae)</li>
                        <li>Lettera di motivazione (se fornita)</li>
                    </ul>
                    <h3>Finalità del trattamento</h3>
                    <div className="privacy-policy-subcontainer">I dati personali raccolti saranno trattati esclusivamente per gestire la tua candidatura, valutare il tuo profilo e contattarti per eventuali colloqui o ulteriori informazioni.</div>
                    <h3>Base giuridica del trattamento</h3>
                    <div className="privacy-policy-subcontainer">Il trattamento dei tuoi dati si basa sul consenso che ci fornisci inviando il modulo di candidatura.</div>
                    <h3>Conservazione dei dati</h3>
                    <div className="privacy-policy-subcontainer">I tuoi dati saranno conservati per il tempo necessario alla selezione del personale e, comunque, non oltre 12 mesi dal momento dell'invio. Al termine del periodo di conservazione, i dati saranno eliminati o resi anonimi.</div>
                    <h3>Condivisione dei dati</h3>
                    <div className="privacy-policy-subcontainer">I tuoi dati personali non saranno condivisi con terze parti, salvo obblighi di legge o autorizzazioni esplicite. I dati potranno essere accessibili a persone autorizzate all’interno dell’Azienda, che sono tenute al rispetto della riservatezza e della protezione dei dati.</div>
                </div>
                <div id="diritti-del-candidato" className="privacy-policy-container">
                    <h2>3. I tuoi diritti</h2>
                    <div className="privacy-policy-subcontainer">In qualsiasi momento hai il diritto di:</div>
                    <ul className="privacy-policy-list-container">
                        <li>Rettificare eventuali errori nei tuoi dati;</li>
                        <li>Richiedere la cancellazione dei tuoi dati;</li>
                        <li>Limitare il trattamento;</li>
                        <li>Opporsi al trattamento;</li>
                        <li>Richiedere la portabilità dei tuoi dati.</li>
                    </ul>
                    <div>Per esercitare i tuoi diritti, puoi contattarci via email all’indirizzo:
                        <div className='emailto' href="mailto:adrianavittoriaav@gmail.com">adrianavittoriaav@gmail.com</div>
                    </div>
                </div>
                <div id="contatti" className="privacy-policy-container">
                    <h2>4. Contatti</h2>
                    <div className="privacy-policy-subcontainer">Se hai domande o desideri ulteriori informazioni sulla nostra informativa sulla privacy, puoi contattarci tramite email a <span className='emailto' href="mailto:adrianavittoriaav@gmail.com">adrianavittoriaav@gmail.com</span> o al nostro indirizzo fisico - Sede legale: Via S. Domenico 42 – 80127 Napoli NA Italia – .</div>
                </div>
                <div id="aggiornamenti" className="privacy-policy-container">
                    <h2>5. Aggiornamenti</h2>
                    <div className="privacy-policy-subcontainer">La presente informativa potrebbe essere aggiornata periodicamente per riflettere eventuali modifiche normative o nuovi processi di gestione dei dati. La versione aggiornata sarà sempre disponibile su questa pagina.</div>
                </div>
            </div>
        </div>

    )
}

export default PrivacyPolicy;