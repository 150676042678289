import Credits from "./pages/credits/Credits";
import Home from "./pages/home/Home";
import { Route, Routes } from "react-router-dom";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useAppContext } from './context';
import { initGA } from "./utils/ga-utils";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import LavoraConNoi from "./pages/jobs/LavoraConNoi";
import { useEffect, useState } from "react";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import ToasterManager from "./components/toaster/ToasterManager";

function App() {
  const { getConsent, setGetConsent } = useAppContext();

  const handleAcceptCookie = () => {
    const storedConsent = localStorage.getItem('cookieConsent');

    if (storedConsent !== 'true') {
      setGetConsent(true);
      localStorage.setItem('cookieConsent', 'true');  // Salva il consenso nel localStorage
      initGA(`${process.env.REACT_APP_MEASUREMENT_ID}`);  // Inizializza Google Analytics
    }
  };

  // Recupera il consenso salvato durante il caricamento dell'app
  useEffect(() => {
    const storedConsent = localStorage.getItem('cookieConsent');
    if (storedConsent === 'true') {
      setGetConsent(true);
      initGA(`${process.env.REACT_APP_MEASUREMENT_ID}`);
    }
    // eslint-disable-next-line 
  }, []);

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove(`_ga_${process.env.REACT_APP_MEASUREMENT_ID}`)
    Cookies.remove("_gat");
    Cookies.remove("_gid");
    Cookies.remove("Cookie-Consent");
    setGetConsent(false)
  };

  const [isVisible, setIsVisible] = useState(false);
  // const { btnHide } = useAppContext();

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  }, []);

  const goTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <div className='contancs-socials'>
        <div className='emailto' href="mailto:adrianavittoriaav@gmail.com">adrianavittoriaav@gmail.com</div>
        <div className='socials'>
          <div>
            <a href='https://www.instagram.com/ag.impresa.pulizie?igsh=MTJmd2JkdWE5aGZ0aQ==' target='_black' rel="noopener noreferrer">
              <img className='social-icons' src='/ag-icons/instagram.png' alt='instagram'></img>
            </a>
          </div>
          <div>
            <a href='https://www.facebook.com/profile.php?id=61554507544332' target='_black' rel="noopener noreferrer">
              <img className='social-icons' src='/ag-icons/facebook.png' alt='facebook'></img>
            </a>
          </div>
          <div>
            <a href="https://wa.me/393669731155?text=Salve%20avrei%20interesse%20a%20ricevere%20un%20preventivo%20per%20i%20vostri%20servizi/prodotti" target='_black'>
              <img className='social-icons' src='/ag-icons/whatsapp.png' alt='whatsapp'></img>
            </a>
          </div>
        </div>
      </div>
      <ToasterManager />
      <Navbar />
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/credits" element={<Credits />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/jobs" element={<LavoraConNoi />} />
      </Routes>
      {!getConsent || undefined ?

        <CookieConsent
          enableDeclineButton
          flipButtons
          cookieName="Cookie-Consent"
          style={{ backgroundColor: "#ffffff", color: "black", fontFamily: "monospace", opacity: "0.8", }}
          buttonStyle={{ borderRadius: "12px", fontFamily: "monospace" }}
          declineButtonStyle={{ borderRadius: "12px", fontFamily: "monospace" }}
          onAccept={async () => {
            // triggered if user accept
            await handleAcceptCookie();
            console.log("Accept was triggered by clicking the Accept button");
          }}
          onDecline={() => {
            alert("nay!");
            handleDeclineCookie();
          }}
          expires={1}
        >
          Utilizziamo i cookie 🍪
          <br />
          Per una migliore esperienza su ag-pulizie utilizziamo i cookie.
          <br />
          <a href="/privacy-policy">Policy di utilizzo dei cookie.</a>
        </CookieConsent>
        :
        null
      }
      <Footer />

      {isVisible === true ?
        <div id='scroll-up' className='container-scroll-up-btn' onClick={goTop}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="scroll-up">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
          </svg>
        </div>
        :
        null}
    </>
  );
}

export default App;
