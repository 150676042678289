import { useEffect, useState } from "react";
import "./toaster.css";
const Toaster = ({ message, type, onClose = () => { } }) => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(false);
            onClose();
        }, 8000);
        return () => clearTimeout(timer);
    }, [onClose])

    const toasterClass = type === 'success' ? '✅' : '❌';
    return (
        <>
            {show ?
                <div id="toaster" >

                    <div className="toaster-container">
                        <div className="close-btn" onClick={onClose}>x</div>
                        <div className="toaster-message">{message} {toasterClass}
                        </div>
                    </div>
                </div>
                :
                null
            }
        </>
    )
}

export default Toaster;