import React from 'react';
import "./about.css";

const About = () => {
    return (
        <div id='about'>
            <h2 className='title'>Chi siamo - A.G Pulizie</h2>
            <div className='about-info'>
                <p>
                L`impresa A.G Pulizie opera nel territorio campano dal 2019. Offre servizi di pulizie ordinarie e straordinarie, in particolare di: appartamenti e abitazioni private, uffici aziendali, spazi commerciali, studi medici e anche pulizie B&B.
                L'azienda offre la personalizzazione dei servizi secondo le esigenze del singolo cliente per garantire un lavoro di massima qualità, professionalità e soprattutto di affidabilità.
                </p>
            </div>
        </div>
    );
}

export default About;
