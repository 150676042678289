import { useState } from "react";
import emailjs from '@emailjs/browser';
import "./lavora-con-noi.css"
import { inputSanitation } from "../../utils/sanitation";
import useNotification from "../../hooks/useNotification";
const LavoraConNoi = () => {
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [fasciaOraria, setFasciaOraria] = useState("");
    //const [pdf, setPdf] = useState("");
    const [letteraMotivazionale, setLetteraMotivazionale] = useState("");
    const notify = useNotification();


    const sendEmailHandler = async (e) => {
        e.preventDefault();
        console.log("Name:", name)
        console.log("Username:", surname);
        console.log("fascia oraria", fasciaOraria);
        //console.log("pfd", pdf);
        console.log("email", email);
        console.log("phone", phone)
        const result = inputSanitation(name, surname, email, phone, letteraMotivazionale);
        if (result !== "Is Valid") {
            notify(result, "error");
            return;
        }
        let templateParams = {
            from_name: email,
            message: `
            Candidatura di Lavoro \n \n
            Nome : ${name}\n
            Congnome : ${surname}\n
            Email : ${email}\n
            Numero di telefono: ${phone}\n
            Fascia oraria: ${fasciaOraria}\n \n
            Lettera Motivazionale: ${letteraMotivazionale}\n
            `
        };
        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, templateParams, process.env.REACT_APP_PUBLIC_KEY)
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                if (response.status === 200) {
                    notify("Email inviata con successo!","success")
                    resetAllState();
                }
            })
            .catch((error) => {
                console.log('FAILED...', error);
                resetAllState();
                notify("Invio fallito", "error")
            });
    }

    const resetAllState = () => {
        setName("");
        setSurname("");
        setEmail("");
        setPhone("");
        setFasciaOraria("");
        //setPdf("");
        setLetteraMotivazionale("");
    }
    return (
        <div className="page-container">
            <div id="lavora-con-noi">
                <h1>Lavora con noi - Personale per Pulizie</h1>

                <div className="privacy-policy-container">
                    Stiamo cercando personale motivato e affidabile per diverse fasce orarie. <br />
                    Se sei una persona puntuale e precisa, con attenzione ai dettagli, questa potrebbe essere l'opportunità giusta per te!
                </div>

                <h3>Turni disponibili</h3>

                <div className="privacy-policy-subcontainer">
                    Offriamo contratti part-time mensili, con un minimo di <strong>2 ore di lavoro al giorno</strong>, distribuite in diverse fasce orarie in base alle esigenze di uffici, B&B e altre strutture. <br />
                </div>
                <h4 className="underline">Ecco le fasce orarie disponibili:</h4>
                <ul className="privacy-policy-list-container">
                    <li><strong>Mattina presto (Uffici)</strong>:<br />
                        5:00 - 7:00<br />
                        Ideale per chi preferisce lavorare nelle prime ore del giorno, quando gli uffici sono ancora chiusi. Le attività principali includono la pulizia di scrivanie, sale riunioni e aree comuni.</li>

                    <li><strong>Orario di B&B (Pulizie camere)</strong>:<br />
                        10:00 - 14:00<br />
                        Se preferisci lavorare in un ambiente dinamico, questo turno prevede la pulizia delle camere, delle aree comuni e la preparazione degli alloggi per i nuovi ospiti.</li>

                    <li><strong>Pomeriggio (Altri incarichi)</strong>:<br />
                        18:00 - 20:00<br />
                        Fascia oraria ideale per chi cerca un lavoro part-time nel tardo pomeriggio. Le mansioni variano a seconda delle esigenze, incluse pulizie di fine giornata o attività di supporto.</li>
                </ul>

                <h4 className="underline">Cosa offriamo</h4>

                <ul className="privacy-policy-list-container">
                    <li>Contratto part-time mensile con ore garantite.</li>
                    <li>Possibilità di conciliare il lavoro con altri impegni grazie alle fasce orarie flessibili.</li>
                    <li>Ambiente di lavoro professionale dinamico.</li>
                    <li>Opportunità di crescita e formazione per chi desidera imparare e migliorare.</li>
                </ul>

                <h4 className="underline">Requisiti</h4>

                <ul className="privacy-policy-list-container">
                    <li>Disponibilità per almeno 2 ore al giorno (turni da concordare).</li>
                    <li>Puntualità e affidabilità.</li>
                    <li>Capacità di lavorare in modo autonomo e con attenzione ai dettagli.</li>
                    <li>Preferibilmente esperienza pregressa nel settore delle pulizie, ma non essenziale.</li>
                </ul>

                <h4 className="underline">Come candidarsi</h4>
                <div className="privacy-policy-subcontainer">
                    <p>Compila il modulo di candidatura qui sotto, selezionando la fascia oraria di tua preferenza e completando la sezione dedicata alla lettera di presentazione, dove potrai descrivere la tua esperienza lavorativa e motivare il tuo interesse per la posizione.</p>
                </div>
                <div className="privacy-policy-subcontainer form-style">
                    <form className="form" onSubmit={sendEmailHandler}>
                        <h3 className="form-headers">Modulo di candidatura</h3>
                        <label htmlFor="name">
                            Nome :
                            <input id="name" type="text" name="name" className="input-text-form" value={name} onChange={(e) => setName(e.target.value)} required={true} />
                        </label>
                        <br />
                        <label htmlFor="surname">
                            Cognome :
                            <input id="surname" type="text" name="surname" className="input-text-form" value={surname} onChange={(e) => setSurname(e.target.value)} required={true} />
                        </label>
                        <br />
                        <label htmlFor="email">
                            Email :
                            <input id="email" type="email" name="email" className="input-text-form" value={email} onChange={(e) => setEmail(e.target.value)} required={true} />
                        </label>
                        <br />
                        <label htmlFor="phone">
                            Telefono cellulare :
                            <input id="phone" type="tel" name="phone" className="input-text-form" value={phone} onChange={(e) => setPhone(e.target.value)} required={true} />
                        </label>
                        <br />
                        <label htmlFor="fascia-oraria">Fascia oraria preferita:</label>
                        <select id="fascia-oraria" name="fascia-oraria" value={fasciaOraria} className="input-text-form" onChange={(e) => setFasciaOraria(e.target.value)}>
                            <option value="">Seleziona fascia oraria</option>
                            <option value="5-7">5:00-7:00</option>
                            <option value="10-14">10:00-14:00</option>
                            <option value="18-20">18:00-20:00</option>
                        </select>
                        <br />
                        {/* <label htmlFor="cv">Curriculum Vitae (PDF):</label>
                        <input type="file" id="cv" name="cv" accept=".pdf" onChange={(e) => setPdf(e.target.files[0])} className="input-text-form btn-contacts"></input>
                        <br /> */}
                        <label htmlFor="lettera">Lettera di presentazione :</label>
                        <br />
                        <textarea id="lettera" name="lettera" rows={10} cols={40} className="text-area-form" value={letteraMotivazionale} placeholder="Descrivi qui la tua esperienza lavorativa, le tue competenze e le motivazioni che ti spingono a candidarti per questa posizione..." onChange={(e) => setLetteraMotivazionale(e.target.value)} required={true}></textarea>
                        <br />
                        <input type="submit" value="Submit" className="form-submit-btn" />
                    </form>
                </div>
                <div className="privacy-policy-container">
                    <div className="privacy-policy-subcontainer">
                        <h3>Informazioni di contatto</h3>

                        <p>Se hai domande o desideri maggiori informazioni, non esitare a contattarci all'indirizzo email <a className='emailto' href="mailto:adrianavittoriaav@gmail.com">adrianavittoriaav@gmail.com</a> o al numero +39 366 973 1155 .</p>
                    </div>


                    <div className="privacy-policy-subcontainer">
                        <h3>Informativa sulla privacy</h3>

                        <p>I dati personali forniti verranno trattati esclusivamente per la gestione delle candidature e in conformità con il Regolamento (UE) 2016/679 (GDPR). Consulta la nostra <a href="/privacy-policy">Informativa sulla Privacy</a> per ulteriori dettagli.</p>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default LavoraConNoi;