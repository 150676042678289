import validator from 'validator';
export const inputSanitation = (name, surname, email, phone, letteraMotivazionale) => {
    if (typeof name !== "string" || typeof surname !== "string") {
        return "Si prega di inserire lettere e non numeri"
    }
    if (isStringEmpty(name) || isStringEmpty(surname) || isStringEmpty(email) || isStringEmpty(phone) || isStringEmpty(letteraMotivazionale)) {
        return "Si prega di riempire tutti campi obbligatori"
    }

    if (!isValidEmail(email)) {
        return "Si prega di inserire un email valida!"
    }
    if (!isValidPhoneNumber(phone)) {
        return "Si prega di inserire un numero di telefono valido!"
    }
    return "Is Valid"
}

function isStringEmpty(str) {
    // Usa trim() per rimuovere gli spazi bianchi all'inizio e alla fine
    return str.trim() === "";
}

const isValidEmail = (email) => {
    if (validator.isEmail(email)){
        return true
    } else {
        return false
    }
}

const isValidPhoneNumber = (phoneNumber) => {
    if (validator.isMobilePhone(phoneNumber, 'it-IT')) {
        return true
    } else {
        return false
    }
} 