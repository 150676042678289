import React from 'react';
import "./contacts.css";


const Contacts = () => {
    return (
        <div id='contacts'>
        <div className='contacts-container'>
            <div className='space'></div>
            <div className='contact-info'>
            <h2 className='title'>Contattaci</h2>
            <div className='contacts-box'>
              <div className='ag-icon-container'>
              <img className='ag-icons' src='/ag-icons/phone.png' alt='phone-icon'></img>
              </div>
              <a href='tel:+393669731155'>
                <p className='contacts-box-text'>+39 366 973 1155</p>
                </a>
            </div>
            <div className='contacts-box'>
              <div className='ag-icon-container'>
              <img className='ag-icons' src='/ag-icons/email.png' alt='email-icon'></img>
              </div>
              <a href="mailto:adrianavittoriaav@gmail.com">
                <p className='contacts-box-text'>adrianavittoriaav@gmail.com</p>
              </a>
            </div>
            <div className='contacts-box'>
              <div className='ag-icon-container'>
              <img className='ag-icons' src='/ag-icons/position.png' alt='map-icon'></img>
              </div>
              <p className='contacts-box-text'>Via S. Domenico, 42, 80127 Napoli NA, Italy</p>
            </div>
            <div className='contacts-box'>
                <div>
                    <a href='https://www.google.com/maps/place/Ag-Pulizie/@40.8425633,14.2149172,19z/data=!4m6!3m5!1s0x133b097af712047f:0x6b89304c776714d!8m2!3d40.842602!4d14.2152729!16s%2Fg%2F11svr9svzf?entry=ttu'>
                    <img fetchpriority="high" className='img-position' src='/images/position.png' alt='position'></img>
                    </a>
              </div>
            </div>
            </div>
          <div className='container-text'>
            <h3 className='container-text-header'>Contattaci oggi per un preventivo gratuito</h3>
            <div className='hr-container'>
             <hr className='hr'></hr> 
            </div>
            <div className='p-contacts'>
              <p>Sei sempre di corsa? 
                Niente paura: pensiamo noi a venire a casa tua, nel tuo ufficio o in negozio.
                Pochi minuti e avrai tutte le risposte che ti servono!</p>
                <a href="mailto:adrianavittoriaav@gmail.com">
                <button id='require-information' className='btn-contacts' >Richiedi informazioni</button>
                </a>
            </div>
            </div>
          </div> 
        </div>
    );
}

export default Contacts;
