import React, { useState, useEffect } from 'react';
import Services from '../services/Services';
import About from '../about/About';
import Contacts from '../contacts/Contacts';
import "./home.css"      
const Home = () => {
    return (
        <>
            <div id='home'>
                <div className='home'>
                    <div className='home-text'>
                        <h1>A.G Impresa di Pulizie</h1>
                        <p>specializzata nella pulizia e sanificazione</p>
                    </div>
                </div>
                <Services />
                <About />
                <Contacts />
                
            </div>
        </>
    );
}

export default Home;
