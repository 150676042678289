import { useAppContext } from "../../context";
import Toaster from "./Toaster";

const ToasterManager = () => {
    const { notification, setNotification } = useAppContext();

    const closeNotification = () => {
        setNotification(null);
    }
    return (
        <>
            {notification && (
                <Toaster
                    message={notification.message}
                    type={notification.type}
                    onClose={closeNotification} />
            )}
        </>
    )
}
export default ToasterManager;