import React from 'react';
import "./services.css";

const Services = () => {
  return (
    <div id='services'>
      <h2 className='title title-services'>I nostri Servizi</h2>
      <div className='container-cards'>
        <div className='card-services'>
          <img fetchpriority="high" className='card-img' src='/images/casa.png' alt='casa' loading="lazy" />
          <div className='card-body'>
            <h2 className='card-title'>Appartamenti</h2>
            <p>La nostra impresa si occupa di pulizie ordinarie e straordinarie per appartamenti, B&B e condomini. Offriamo orari flessibili e personale riservato ma soprattutto altamente qualificato.</p>
          </div>
        </div>
        <div className='card-services'>
          <img fetchpriority="high" className='card-img' src='/images/ufficio.png' alt='casa' loading="lazy" />
          <div className='card-body'>
            <h2 className='card-title'>Uffici</h2>
            <p>La pulizia degli uffici viene effettuata da personale selezionato con l’ausilio di macchinari professionali e prodotti di alta qualità, rendendo  gli ambienti di lavoro profumati e sanificati. </p>
          </div>
        </div>
        <div className='card-services'>
          <img fetchpriority="high" className='card-img' src='/images/negozio.png' alt='casa' loading="lazy" />

          <div className='card-body'>
            <h2 className='card-title'>Attivita Commerciali</h2>
            <p>Ag Pulizie provvede alla pulizia di spazi ampi come negozi, aziende e showroom . L’obiettivo è permettervi di vendere il vostro prodotto valorizzato da un ambiente circostante pulito e ordinato. </p>
          </div>
        </div>
      </div>


    </div>
  );
}

export default Services;
