import { useAppContext } from "../context";

const useNotification = () => {
    const { setNotification } = useAppContext();

    const notify = (message, type = "success" || "error") => {
        setNotification({message, type})
    }
    return notify
}

export default useNotification;